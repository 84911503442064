<template>
    <div>
        <b-row>
            <b-col>
                <div class="card card-border box-shadow-1">
                    <div
                        class="row m-0 py-1 bg-gradient-primary header-top box-shadow-0"
                    >
                        <div class="col flex-row">
                            <feather-icon
                                icon="HomeIcon"
                                size="25"
                                class="col py-auto"
                            ></feather-icon>
                            <p
                                class="text-center font-weight-bolder font-medium-3 mt-25 mb-0 col"
                            >
                                Entidades
                                <feather-icon
                                    icon="HelpCircleIcon"
                                    v-b-tooltip.hover.top="
                                        'Estadísticas sobre el total de entidades'
                                    "
                                ></feather-icon>
                            </p>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-center m-1">
                        <div
                            :class="`${
                                entidadDateSelected === 'hoy'
                                    ? 'bg-gradient-primary rounded-left'
                                    : 'text-dark border rounded-left'
                            } px-1 cursor-pointer py-25`"
                            @click="dateSelected('hoy')"
                        >
                            Hoy
                        </div>
                        <div
                            :class="`${
                                entidadDateSelected === 'ult_semana'
                                    ? 'bg-gradient-primary'
                                    : 'text-dark border'
                            } px-1 cursor-pointer py-25`"
                            @click="dateSelected('ult_semana')"
                        >
                            Última semana
                        </div>
                        <div
                            :class="`${
                                entidadDateSelected === 'ult_mes'
                                    ? 'bg-gradient-primary'
                                    : 'text-dark border'
                            } px-1 cursor-pointer py-25`"
                            @click="dateSelected('ult_mes')"
                        >
                            Último mes
                        </div>
                        <div
                            :class="`${
                                entidadDateSelected === 'ult_ano'
                                    ? 'bg-gradient-primary rounded-right'
                                    : 'text-dark border rounded-right'
                            } px-1 cursor-pointer py-25`"
                            @click="dateSelected('ult_ano')"
                        >
                            Último año
                        </div>
                    </div>
                    <div class="row justify-content-around m-1">
                        <div
                            v-for="(indicador, index) in indicadores"
                            :key="index"
                            class="text-center mx-auto w-30"
                        >
                            <!-- <p>{{ indicador.title }}</p>    -->
                            <div class="indicador-circular border mr-0 pr-0">
                                <p
                                    class="font-large-2 font-weight-bolder text-dark text-shadow-1 mt-3"
                                >
                                    {{ indicador.number }}
                                </p>
                                <p class="font-small-2 line-height-1">
                                    {{ indicador.title }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col>
                <div class="card card-border box-shadow-1">
                    <div
                        class="row m-0 py-1 bg-gradient-info header-top box-shadow-0"
                    >
                        <div class="col flex-row">
                            <feather-icon
                                icon="UsersIcon"
                                size="25"
                                class="col py-auto"
                            ></feather-icon>
                            <p
                                class="text-center font-weight-bolder font-medium-3 mt-25 mb-0 col"
                            >
                                Profesionales
                                <feather-icon
                                    icon="HelpCircleIcon"
                                    v-b-tooltip.hover.top="
                                        'Profesionales con mejor rendimiento'
                                    "
                                ></feather-icon>
                            </p>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <b-dropdown
                                text="Hoy"
                                size="sm"
                                variant="gradient-info"
                                right
                                class="mx-1 mt-50"
                            >
                                <b-dropdown-item>Hoy</b-dropdown-item>
                                <b-dropdown-item>Última semana</b-dropdown-item>
                                <b-dropdown-item>Último mes</b-dropdown-item>
                                <b-dropdown-item>Último año</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div class="row px-1">
                        <div class="col">
                            <div
                                v-for="(
                                    profesional, index
                                ) in mejoresProfesionales"
                                :key="index"
                                :class="`d-flex align-items-center px-1 pt-25 ${
                                    index === 0 ? 'mt-1' : ''
                                }`"
                            >
                                <!-- <span
                                    :class="`font-italic ${
                                        index === 0
                                            ? 'text-yellow mr-2'
                                            : 'text-dark mr-1'
                                    } font-large-2 font-weight-bolder `"
                                    >{{ index + 1 }}</span
                                > -->

                                <img
                                    :src="`/img/general/medal_${index + 1}.png`"
                                    alt="Medalla"
                                    height="36"
                                    width="37"
                                />

                                <div>
                                    <img
                                        v-if="index === 0"
                                        src="/img/general/corona.png"
                                        alt="Corona"
                                        class="corona animate__animated animate__rollIn"
                                    />
                                    <b-avatar
                                        variant="dark"
                                        class="mr-1"
                                        size="35"
                                    ></b-avatar>
                                </div>
                                <div>
                                    <p class="pb-0 mb-0">
                                        {{ profesional.nombre }}
                                    </p>
                                    <p class="pb-0 mb-0">
                                        {{ profesional.puntaje }} &#11088;
                                    </p>
                                </div>
                                <div class="col"></div>
                                <div class="text-center w-50">
                                    <p class="m-0">Asistencias</p>
                                    <div class="d-flex justify-content-around">
                                        <p
                                            class="text-center text-success text-shadow border rounded-pill px-2"
                                            v-b-tooltip.hover.top="'Realizadas'"
                                        >
                                            {{
                                                profesional.asistencias
                                                    .realizadas
                                            }}
                                        </p>
                                        <p
                                            class="text-center text-info text-shadow border rounded-pill px-2"
                                            v-b-tooltip.hover.top="
                                                'Reprogramadas'
                                            "
                                        >
                                            {{
                                                profesional.asistencias
                                                    .reprogramadas
                                            }}
                                        </p>
                                        <p
                                            class="text-center text-danger text-shadow border rounded-pill px-2"
                                            v-b-tooltip.hover.top="'Canceladas'"
                                        >
                                            {{
                                                profesional.asistencias
                                                    .canceladas
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col>
                <div class="card card-border box-shadow-1">
                    <div
                        class="row m-0 py-1 bg-gradient-danger header-top box-shadow-0"
                    >
                        <div class="col flex-row">
                            <feather-icon
                                icon="HeartIcon"
                                size="25"
                                class="col py-auto"
                            ></feather-icon>
                            <p
                                class="text-center font-weight-bolder font-medium-3 mt-25 mb-0 col"
                            >
                                Pacientes
                                <feather-icon
                                    icon="HelpCircleIcon"
                                    v-b-tooltip.hover.top="
                                        'Actividad de los pacientes'
                                    "
                                ></feather-icon>
                            </p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row justify-content-end">
                            <b-dropdown
                                text="Hoy"
                                variant="gradient-danger"
                                size="sm"
                                right
                                class="mx-1 mt-50"
                            >
                                <b-dropdown-item>Hoy</b-dropdown-item>
                                <b-dropdown-item>Última semana</b-dropdown-item>
                                <b-dropdown-item>Último mes</b-dropdown-item>
                                <b-dropdown-item>Último año</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="row pt-2">
                            <div class="col text-center">
                                <p
                                    class="text-dark text-shadow-1 font-large-2 font-weight-bolder"
                                >
                                    200
                                </p>
                                <p>Pacientes activos</p>
                            </div>
                        </div>
                        <div class="row py-2 text-center">
                            <div class="col">
                                <p
                                    class="text-center text-dark text-shadow-1 font-large-2 font-weight-bolder"
                                >
                                    200
                                </p>
                                <p>Soporte realizados</p>
                            </div>
                            <div class="col">
                                <p
                                    class="text-center text-dark text-shadow-1 font-large-2 font-weight-bolder"
                                >
                                    12
                                </p>
                                <p>Dispositivos usados</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { ref } from "@vue/composition-api";

export default {
    setup(props, context) {
        const entidadDateSelected = ref("hoy");

        const indicadores = ref([
            {
                title: "Asistencias realizadas",
                number: 20,
                period: "Última semana",
            },
            {
                title: "Pacientes nuevos",
                number: 30,
                period: "Última semana",
            },
            {
                title: "Dispositivos entregados",
                number: 40,
                period: "Última semana",
            },
        ]);

        const mejoresProfesionales = ref([
            {
                nombre: "Gian Galán",
                puntaje: 4.9,
                asistencias: {
                    realizadas: 12,
                    reprogramadas: 20,
                    canceladas: 1,
                },
            },
            {
                nombre: "Luz",
                puntaje: 4.5,
                asistencias: {
                    realizadas: 43,
                    reprogramadas: 1,
                    canceladas: 20,
                },
            },
            {
                nombre: "Oscar Acelas",
                puntaje: 4.3,
                asistencias: {
                    realizadas: 12,
                    reprogramadas: 3,
                    canceladas: 22,
                },
            },
        ]);

        function dateSelected(date) {
            entidadDateSelected.value = date;
        }

        return {
            dateSelected,
            indicadores,
            entidadDateSelected,
            mejoresProfesionales,
        };
    },
};
</script>
<style lang="css" scope>
.header-top {
    border-radius: 30px 30px 0px 0px;
}
.card-border {
    border-radius: 30px 30px 30px 30px;
}
.indicador-circular {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.text-yellow {
    color: #ffcc00;
}
.corona {
    position: absolute;
    width: 50px;
    height: 30px;
    z-index: 1;
    top: 4%;
    left: 57px;
}
</style>
