var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"card card-border box-shadow-1"},[_c('div',{staticClass:"row m-0 py-1 bg-gradient-primary header-top box-shadow-0"},[_c('div',{staticClass:"col flex-row"},[_c('feather-icon',{staticClass:"col py-auto",attrs:{"icon":"HomeIcon","size":"25"}}),_c('p',{staticClass:"text-center font-weight-bolder font-medium-3 mt-25 mb-0 col"},[_vm._v(" Entidades "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                                    'Estadísticas sobre el total de entidades'
                                ),expression:"\n                                    'Estadísticas sobre el total de entidades'\n                                ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"HelpCircleIcon"}})],1)],1)]),_c('div',{staticClass:"d-flex flex-row justify-content-center m-1"},[_c('div',{class:((_vm.entidadDateSelected === 'hoy'
                                ? 'bg-gradient-primary rounded-left'
                                : 'text-dark border rounded-left') + " px-1 cursor-pointer py-25"),on:{"click":function($event){return _vm.dateSelected('hoy')}}},[_vm._v(" Hoy ")]),_c('div',{class:((_vm.entidadDateSelected === 'ult_semana'
                                ? 'bg-gradient-primary'
                                : 'text-dark border') + " px-1 cursor-pointer py-25"),on:{"click":function($event){return _vm.dateSelected('ult_semana')}}},[_vm._v(" Última semana ")]),_c('div',{class:((_vm.entidadDateSelected === 'ult_mes'
                                ? 'bg-gradient-primary'
                                : 'text-dark border') + " px-1 cursor-pointer py-25"),on:{"click":function($event){return _vm.dateSelected('ult_mes')}}},[_vm._v(" Último mes ")]),_c('div',{class:((_vm.entidadDateSelected === 'ult_ano'
                                ? 'bg-gradient-primary rounded-right'
                                : 'text-dark border rounded-right') + " px-1 cursor-pointer py-25"),on:{"click":function($event){return _vm.dateSelected('ult_ano')}}},[_vm._v(" Último año ")])]),_c('div',{staticClass:"row justify-content-around m-1"},_vm._l((_vm.indicadores),function(indicador,index){return _c('div',{key:index,staticClass:"text-center mx-auto w-30"},[_c('div',{staticClass:"indicador-circular border mr-0 pr-0"},[_c('p',{staticClass:"font-large-2 font-weight-bolder text-dark text-shadow-1 mt-3"},[_vm._v(" "+_vm._s(indicador.number)+" ")]),_c('p',{staticClass:"font-small-2 line-height-1"},[_vm._v(" "+_vm._s(indicador.title)+" ")])])])}),0)])])],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',[_c('div',{staticClass:"card card-border box-shadow-1"},[_c('div',{staticClass:"row m-0 py-1 bg-gradient-info header-top box-shadow-0"},[_c('div',{staticClass:"col flex-row"},[_c('feather-icon',{staticClass:"col py-auto",attrs:{"icon":"UsersIcon","size":"25"}}),_c('p',{staticClass:"text-center font-weight-bolder font-medium-3 mt-25 mb-0 col"},[_vm._v(" Profesionales "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                                    'Profesionales con mejor rendimiento'
                                ),expression:"\n                                    'Profesionales con mejor rendimiento'\n                                ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"HelpCircleIcon"}})],1)],1)]),_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('b-dropdown',{staticClass:"mx-1 mt-50",attrs:{"text":"Hoy","size":"sm","variant":"gradient-info","right":""}},[_c('b-dropdown-item',[_vm._v("Hoy")]),_c('b-dropdown-item',[_vm._v("Última semana")]),_c('b-dropdown-item',[_vm._v("Último mes")]),_c('b-dropdown-item',[_vm._v("Último año")])],1)],1)]),_c('div',{staticClass:"row px-1"},[_c('div',{staticClass:"col"},_vm._l((_vm.mejoresProfesionales),function(profesional,index){return _c('div',{key:index,class:("d-flex align-items-center px-1 pt-25 " + (index === 0 ? 'mt-1' : ''))},[_c('img',{attrs:{"src":("/img/general/medal_" + (index + 1) + ".png"),"alt":"Medalla","height":"36","width":"37"}}),_c('div',[(index === 0)?_c('img',{staticClass:"corona animate__animated animate__rollIn",attrs:{"src":"/img/general/corona.png","alt":"Corona"}}):_vm._e(),_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"dark","size":"35"}})],1),_c('div',[_c('p',{staticClass:"pb-0 mb-0"},[_vm._v(" "+_vm._s(profesional.nombre)+" ")]),_c('p',{staticClass:"pb-0 mb-0"},[_vm._v(" "+_vm._s(profesional.puntaje)+" ⭐ ")])]),_c('div',{staticClass:"col"}),_c('div',{staticClass:"text-center w-50"},[_c('p',{staticClass:"m-0"},[_vm._v("Asistencias")]),_c('div',{staticClass:"d-flex justify-content-around"},[_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Realizadas'),expression:"'Realizadas'",modifiers:{"hover":true,"top":true}}],staticClass:"text-center text-success text-shadow border rounded-pill px-2"},[_vm._v(" "+_vm._s(profesional.asistencias .realizadas)+" ")]),_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                                            'Reprogramadas'
                                        ),expression:"\n                                            'Reprogramadas'\n                                        ",modifiers:{"hover":true,"top":true}}],staticClass:"text-center text-info text-shadow border rounded-pill px-2"},[_vm._v(" "+_vm._s(profesional.asistencias .reprogramadas)+" ")]),_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Canceladas'),expression:"'Canceladas'",modifiers:{"hover":true,"top":true}}],staticClass:"text-center text-danger text-shadow border rounded-pill px-2"},[_vm._v(" "+_vm._s(profesional.asistencias .canceladas)+" ")])])])])}),0)])])]),_c('b-col',[_c('div',{staticClass:"card card-border box-shadow-1"},[_c('div',{staticClass:"row m-0 py-1 bg-gradient-danger header-top box-shadow-0"},[_c('div',{staticClass:"col flex-row"},[_c('feather-icon',{staticClass:"col py-auto",attrs:{"icon":"HeartIcon","size":"25"}}),_c('p',{staticClass:"text-center font-weight-bolder font-medium-3 mt-25 mb-0 col"},[_vm._v(" Pacientes "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                                    'Actividad de los pacientes'
                                ),expression:"\n                                    'Actividad de los pacientes'\n                                ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"HelpCircleIcon"}})],1)],1)]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-end"},[_c('b-dropdown',{staticClass:"mx-1 mt-50",attrs:{"text":"Hoy","variant":"gradient-danger","size":"sm","right":""}},[_c('b-dropdown-item',[_vm._v("Hoy")]),_c('b-dropdown-item',[_vm._v("Última semana")]),_c('b-dropdown-item',[_vm._v("Último mes")]),_c('b-dropdown-item',[_vm._v("Último año")])],1)],1),_c('div',{staticClass:"row pt-2"},[_c('div',{staticClass:"col text-center"},[_c('p',{staticClass:"text-dark text-shadow-1 font-large-2 font-weight-bolder"},[_vm._v(" 200 ")]),_c('p',[_vm._v("Pacientes activos")])])]),_c('div',{staticClass:"row py-2 text-center"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-center text-dark text-shadow-1 font-large-2 font-weight-bolder"},[_vm._v(" 200 ")]),_c('p',[_vm._v("Soporte realizados")])]),_c('div',{staticClass:"col"},[_c('p',{staticClass:"text-center text-dark text-shadow-1 font-large-2 font-weight-bolder"},[_vm._v(" 12 ")]),_c('p',[_vm._v("Dispositivos usados")])])])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }